<template>
  <div class="inner_item with_right_line">
    <LineHorizontal></LineHorizontal>

    <div class="between_horizontal_lines">
      <ItemContent :item="item"></ItemContent>
      <LineVertical :item="item" position="right"></LineVertical>
    </div>
    <LineHorizontal></LineHorizontal>
  </div>
</template>

<script>
import ItemContent from "@/components/Item/ItemContent.vue";
import LineHorizontal from "@/components/Item/LineHorizontal.vue";
import LineVertical from "@/components/Item/LineVertical.vue";

export default {
  props: ["item"],
  components: {
    LineHorizontal,
    LineVertical,
    ItemContent,
  },
};
</script>

<style lang="scss" scoped>
</style>