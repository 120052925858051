<template>
  <div class="inner_item with_left_line">
    <LineVertical :item="item" position="left"></LineVertical>
    <ItemContent :item="item"></ItemContent>
  </div>
</template>

<script>
import LineVertical from "@/components/Item/LineVertical.vue";
import ItemContent from "@/components/Item/ItemContent.vue";
export default {
  components: {
    LineVertical,
    ItemContent,
  },
  props: ["item"],
};
</script>

<style lang="scss" scoped>
</style>