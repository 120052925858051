<template>
  <div class="line_container" :class="position">
    <div class="line" v-if="position == 'left'"></div>

    <div class="graduations">
      <div class="graduation_principale" v-if="year != ''">
        <div class="year">{{ year }}</div>
      </div>
      <div class="graduation_1" v-else></div>
      <div class="graduation_2"></div>
      <div class="graduation_3"></div>
      <div class="graduation_4"></div>
    </div>
    <div class="line" v-if="position == 'right'"></div>
  </div>
</template>

<script>
export default {
  props: ["item", "position"],
  data() {
    return {
      year: "",
    };
  },
  created() {
    let remove_extension = this.item.media.split(".")[0];
    let split_name = remove_extension.split("-");
    if (split_name.length > 1) {
      this.year = split_name[1];
    }
  },
};
</script>

<style lang="scss" scoped>
</style>