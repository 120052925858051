<template>
  <div class="timeline_item">
    <ContentWithLeftLine v-if="item.line_position == 'left'" :item="item"></ContentWithLeftLine>
    <ContentWithRightLine v-if="item.line_position == 'right'" :item="item"></ContentWithRightLine>
  </div>
</template>

<script>
import ContentWithLeftLine from "./Item/ItemWithLeftLine.vue";
import ContentWithRightLine from "./Item/ItemWithRightLine.vue";

export default {
  props: ["item"],
  components: {
    ContentWithLeftLine,
    ContentWithRightLine,
  },

  methods: {},
};
</script>

<style lang="scss">
</style>