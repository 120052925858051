import { Timeline_Item } from "./Timeline_Item";

export default class List_Timeline_Items {
    list = [];
    constructor() { }

    async get() {

        fetch('https://070123.be/api/endpoints/medias/get.php').then((response) => {
            return response.json();
        })
            .then((data) => {
                console.log(data);
                for (let i = 0; i < data.length; i++) {
                    let item = new Timeline_Item();
                    item.set(data[i]);
                    this.list.push(item);
                }
            }).catch(function (error) {
                console.log(error);
            });


    }
    reload_comments(item_id) {
        for (let i = 0; i < this.list.length; i++) {
            if (this.list[i].id == item_id) {
                this.list[i].get_comments();
            }
        }
    }
}


