<template>
  <div class="exit_focus">
    <button @click="exit_focus">X</button>
  </div>
  <div class="focus_content">
    <img :src="'https://070123.be/media/' + item.media" alt="" />
  </div>
  <div class="message_section">
    <input v-model="nom" type="text" placeholder="Ton nom" />
    <textarea v-model="message" placeholder="Ton message"></textarea>
    <button @click="send_message()">Envoyer</button>
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      nom: "",
      message: "",
    };
  },
  methods: {
    send_message() {
      if (this.nom == "" || this.message == "") return;
      let data = new FormData();
      data.append("name", this.nom);
      data.append("message", this.message);
      data.append("media_id", this.item.id);

      fetch("https://070123.be/api/endpoints/comment/post.php", {
        method: "POST",
        body: data,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok.");
          }
          this.nom = "";
          this.message = "";
          this.$store.state.focused_media = null;
          this.$store.state.list_timeline_items.reload_comments(this.item.id);
        })
        .catch(console.error);
    },
    exit_focus() {
      this.$store.state.focused_media = null;
    },
    get_image() {
      return require("../assets/img/" + this.item.media);
    },
  },
};
</script>

<style lang="scss" scoped>
.message_section {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
}
</style>