<template>
  <router-view />
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" src="@/assets/scss/style.scss"></style>
<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
