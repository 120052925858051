<template>
  <v-lazy-image @click="onClickMedia()" :src="'https://070123.be/media/' + item.media" class="media" alt="" />
</template>

<script>
import VLazyImage from "v-lazy-image";
export default {
  props: ["item"],
  components: {
    "v-lazy-image": VLazyImage,
  },
  data() {
    return {
      media_zoomed: false,
    };
  },
  methods: {
    // This function is used to get the image path

    onClickMedia() {
      console.log("click");
      this.$store.state.focused_media = this.item;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>