<template>
  <div>
    <h2>Title</h2>
    <h4>Subtitle</h4>
    <p>Content</p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>