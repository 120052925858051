
export class Timeline_Item {
    id;
    type;
    media;
    overlay;
    width;
    year;
    line_position;
    comments = [];

    constructor(type = 'image', media = '', overlay = '', width = 400, year = '', line_position = 'bottom') {
        this.id = 0;
        this.type = type;
        this.media = media;
        this.overlay = overlay;
        this.width = width;
        this.year = year;
        this.line_position = line_position;
    }


    set(data) {
        this.id = data.id;
        this.type = data.type;
        this.media = data.media;
        this.overlay = data.overlay;
        this.width = data.width;
        this.year = data.year;
        this.line_position = data.line_position;
        this.comments = data.comments;
    }

    get_comments() {
        fetch('https://070123.be/api/endpoints/comments/get.php?item_id=' + this.id).then((response) => {
            return response.json();
        })
            .then((data) => {
                this.comments = data;
            }).catch(function (error) {
                console.log(error);
            });
    }
}