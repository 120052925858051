<template>
  <video autoplay controls loop class="media_video">
    <source :src="'https://070123.be/media/' + item.overlay" type="video/mp4" />
  </video>
</template>

<script>
export default {
  props: ["item"],
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>