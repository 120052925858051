<template>
  <div id="splash_screen">
    <div class="line line_right"></div>
    <img class="img_splash" src="../assets/img/background_splash.jpg" alt="" />
    <div class="line"></div>
  </div>
  <div id="main" ref="main">
    <div id="focused" v-if="$store.state.focused_media">
      <FocusedMedia :item="$store.state.focused_media"></FocusedMedia>
    </div>
    <!--    <video autoplay muted loop id="bg_video">
      <source src="../assets/img/bgcetm.mp4" type="video/mp4" />
    </video> -->
    <div id="the_timeline">
      <TimelineItem v-for="(item, index) in list_timeline_items.list" :key="index" :item="item"></TimelineItem>
    </div>
  </div>
</template>

<script>
import List_Timeline_Items from "@/assets/js/List_Timeline_Items";
import TimelineItem from "@/components/TimelineItem.vue";
import FocusedMedia from "@/components/FocusedMedia.vue";

export default {
  name: "HomeView",
  components: { TimelineItem, FocusedMedia },
  data() {
    return {
      screen_orientation: "portrait-primary",
      list_timeline_items: {},
      showFSButton: true,
    };
  },
  methods: {},
  async created() {
    this.list_timeline_items = new List_Timeline_Items();
    await this.list_timeline_items.get();
    this.$store.state.list_timeline_items = this.list_timeline_items;
  },
};
</script>
<style lang="scss">
</style>
