<template>
  <div class="content">
    <ContentImage v-if="item.type == 'image'" :item="item"></ContentImage>
    <ContentText v-if="item.type == 'text'" :item="item"></ContentText>
    <ContentVideo v-if="item.type == 'video'" :item="item"></ContentVideo>
    <div class="comments_section" v-if="item.comments.length > 0">
      <div class="comment_item" v-for="comment in item.comments" :key="comment.id">
        <div class="comment_name">{{ comment.utilisateur }}</div>
        <div class="comment_message">{{ comment.comment }}</div>
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import ContentImage from "./TypeContent/ContentImage.vue";
import ContentText from "./TypeContent/ContentText.vue";
import ContentVideo from "./TypeContent/ContentVideo.vue";
export default {
  components: {
    ContentImage,
    ContentText,
    ContentVideo,
  },
  props: ["item"],
};
</script>

<style lang="scss" scoped>
</style>